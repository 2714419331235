import GlobalFooter from "@fdn/new_footer";
import PropTypes from "prop-types";

export default function Footer({ isMobile, handleScrollUp }) {

	return (
		<>
			<GlobalFooter
				is_mobile={isMobile}
				femaledaily_url={process.env.NEXT_PUBLIC_ROOT_HOME}
				mommiesdaily_url={process.env.NEXT_PUBLIC_MD_DOMAIN}
				girlsbeyond_url={process.env.NEXT_PUBLIC_GB_DOMAIN}
				beautystudio_url={process.env.NEXT_PUBLIC_ECM_DOMAIN}
				handleScrollUp={handleScrollUp}
				footerMenu={[
					{
						title: "About Us",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/about`,
						id: "id_aboutus",
					},
					{
						title: "Terms & Conditions",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/terms-and-conditions`,
						id: "id_tc",
					},
					{
						title: "Awards",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_AWARD}`,
						id: "id_awards",
					},
					{
						title: "Feedback",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/feedback`,
						id: "id_feedback",
					},
					{
						title: "Privacy Policy",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/privacy-policy`,
						id: "id_privacy_policy",
					},
					// {
					//   title: "Newsletter",
					//   target: "_self",
					//   href: `${Env.MD_DOMAIN}`,
					//   id: "id_newsletter",
					// },
					// {
					//   title: "Contact",
					//   target: "_self",
					//   href: `${Env.MD_DOMAIN}/contact`,
					//   id: "id_contact",
					// },
					{
						title: "Help",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/help`,
						id: "id_help",
					},
					{
						title: "Media Kit",
						target: "_self",
						href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/media-kit`,
						id: "id_media_kit",
					},
				]}
			/>
			<style>
				{`
					.footer-taste {
						max-width: 480px;
						margin: auto;
					}
				`}
			</style>
		</>

	);

}

Footer.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	handleScrollUp: PropTypes.func.isRequired,
};
